/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'times-rectangle': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M18.359 20.641l2.281-2.281a.5.5 0 000-.719L16.999 14l3.641-3.641a.5.5 0 000-.719l-2.281-2.281a.5.5 0 00-.719 0L13.999 11l-3.641-3.641a.5.5 0 00-.719 0L7.358 9.64a.5.5 0 000 .719L10.999 14l-3.641 3.641a.5.5 0 000 .719l2.281 2.281a.5.5 0 00.719 0L13.999 17l3.641 3.641a.5.5 0 00.719 0zM28 4.5v19c0 1.375-1.125 2.5-2.5 2.5h-23A2.507 2.507 0 010 23.5v-19C0 3.125 1.125 2 2.5 2h23C26.875 2 28 3.125 28 4.5z"/>'
  }
})
