/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'block': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M8.30039 0.206172C8.75039 0.0355239 9.24961 0.0355239 9.69961 0.206172L16.7309 2.8733C17.4937 3.16334 18 3.89529 18 4.71443V13.2855C18 14.1047 17.4937 14.8359 16.7309 15.1277L9.69961 17.7926C9.24961 17.9332 8.75039 17.9332 8.30039 17.7926L1.27055 15.1277C0.505898 14.8359 0 14.1047 0 13.2855V4.71443C0 3.89529 0.505898 3.16334 1.27055 2.8733L8.30039 0.206172ZM9.09844 1.78381C9.03516 1.75955 8.9332 1.75955 8.90156 1.78381L2.23664 4.31014L9 6.96795L15.7641 4.31014L9.09844 1.78381ZM1.86891 13.5492L8.15625 15.9328V8.45154L1.6875 5.90975V13.2855C1.6875 13.4015 1.75992 13.507 1.86891 13.5492ZM9.84375 15.9328L16.1297 13.5492C16.2387 13.507 16.3125 13.4015 16.3125 13.2855V5.90975L9.84375 8.45154V15.9328Z" fill="#B8B8B8"/>'
  },
  'blocks': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M8.30039 0.206172C8.75039 0.0355239 9.24961 0.0355239 9.69961 0.206172L16.7309 2.8733C17.4937 3.16334 18 3.89529 18 4.71443V13.2855C18 14.1047 17.4937 14.8359 16.7309 15.1277L9.69961 17.7926C9.24961 17.9332 8.75039 17.9332 8.30039 17.7926L1.27055 15.1277C0.505898 14.8359 0 14.1047 0 13.2855V4.71443C0 3.89529 0.505898 3.16334 1.27055 2.8733L8.30039 0.206172ZM9.09844 1.78381C9.03516 1.75955 8.9332 1.75955 8.90156 1.78381L2.23664 4.31014L9 6.96795L15.7641 4.31014L9.09844 1.78381ZM1.86891 13.5492L8.15625 15.9328V8.45154L1.6875 5.90975V13.2855C1.6875 13.4015 1.75992 13.507 1.86891 13.5492ZM9.84375 15.9328L16.1297 13.5492C16.2387 13.507 16.3125 13.4015 16.3125 13.2855V5.90975L9.84375 8.45154V15.9328Z" fill="#B8B8B8"/>'
  }
})
