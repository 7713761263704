/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 30,
    height: 32,
    viewBox: '0 0 30 32',
    data: '<path pid="0" d="M30 26l-7.785-7.785C23.326 16.401 24 14.286 24 12c0-6.626-5.375-12-12-12S0 5.374 0 12c0 6.625 5.374 12 12 12 2.286 0 4.4-.674 6.215-1.781L26 30a1.428 1.428 0 002 0l2-2a1.424 1.424 0 000-2zm-18-6a8 8 0 110-16 8 8 0 010 16z"/>'
  }
})
