/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ethereum': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M15.926 23.96l-9.82-5.8L15.926 32l9.826-13.84-9.828 5.8zM16.074 0l-9.82 16.296 9.82 5.806 9.82-5.8L16.074 0z"/>'
  }
})
