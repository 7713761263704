/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delete': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.537 2.203v2.464H3.464V2.203h4.24L8.964 1h6.073l1.26 1.203h4.24zM4.667 20.537V5.87h14.667v14.667c0 1.318-1.146 2.464-2.464 2.464H7.13c-1.318 0-2.464-1.146-2.464-2.464z"/>'
  }
})
