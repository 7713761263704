/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flame': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M13.531 1.406c1.563 4.188.75 6.469-1 8.281-1.906 2-4.875 3.5-6.969 6.438-2.781 3.906-3.25 12.5 6.781 14.75-4.219-2.219-5.125-8.656-.563-12.688-1.188 3.906 1 6.406 3.719 5.5 2.656-.906 4.406 1 4.344 3.188-.031 1.5-.625 2.781-2.156 3.5 6.531-1.156 9.156-6.563 9.156-10.688 0-5.438-4.844-6.188-2.406-10.75-2.906.25-3.906 2.156-3.625 5.281.188 2.063-1.969 3.469-3.563 2.531-1.281-.781-1.25-2.281-.125-3.406 2.406-2.375 3.344-7.844-3.594-11.938z"/>'
  }
})
