/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-up': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M2.95159 8.67699L7.06669 4.69971C7.20199 4.56169 7.36539 4.50542 7.52933 4.50385C7.69326 4.50227 7.85772 4.55538 7.99565 4.69077L12.1865 8.58811C12.4348 8.83183 12.4661 9.2417 12.1952 9.49041C11.9519 9.7662 11.5424 9.79749 11.2665 9.52669L7.54433 6.06236L3.8895 9.59769C3.6189 9.87374 3.20881 9.85035 2.96027 9.57929C2.68468 9.33584 2.70806 8.92544 2.95159 8.67699Z" fill="white"/>'
  }
})
