/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'link-external': {
    width: 24,
    height: 32,
    viewBox: '0 0 24 32',
    data: '<path pid="0" d="M20 24H4V8.06L8 8V4H0v24h24V18h-4v6zM12 4l4 4-6 6 4 4 6-6 4 4V4H12z"/>'
  }
})
