/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'triangle-arrow-left': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M8.1284 1.94482L4.19091 6.098C4.05419 6.23462 3.9995 6.39856 3.9995 6.5625C3.9995 6.72644 4.05419 6.89038 4.19091 7.027L8.1284 11.1802C8.37449 11.4261 8.78465 11.4534 9.03074 11.1802C9.30418 10.9343 9.33152 10.5244 9.05808 10.2512L5.55809 6.5625L9.05808 2.87382C9.33152 2.60058 9.30418 2.19073 9.03074 1.94482C8.78465 1.67158 8.37449 1.6989 8.1284 1.94482Z" fill="#B8B8B8"/>'
  }
})
