/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tokens': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M0 5.3125C0 2.51758 4.02891 0.25 9 0.25C13.9711 0.25 18 2.51758 18 5.3125V9.25C18 11.7355 13.9711 13.75 9 13.75C4.02891 13.75 0 11.7355 0 9.25V5.3125ZM14.5371 7.39023C15.9223 6.64141 16.3125 5.8293 16.3125 5.3125C16.3125 4.7957 15.9223 3.98359 14.5371 3.20312C13.2047 2.4543 11.25 1.9375 9 1.9375C6.71836 1.9375 4.79531 2.4543 3.46324 3.20312C2.07809 3.98359 1.6875 4.7957 1.6875 5.3125C1.6875 5.8293 2.07809 6.64141 3.46324 7.39023C4.79531 8.1707 6.71836 8.6875 9 8.6875C11.25 8.6875 13.2047 8.1707 14.5371 7.39023ZM9 10.375C8.52188 10.375 8.05078 10.3539 7.59375 10.3152V12.0027C8.04727 12.0414 8.51836 12.0625 9 12.0625C9.48164 12.0625 9.95273 12.0414 10.4062 12.0027V10.3152C9.91758 10.3539 9.47812 10.375 9 10.375ZM4.21875 11.2785C4.87617 11.5246 5.63555 11.725 6.46875 11.8621V10.1711C5.66719 10.041 4.91133 9.81602 4.21875 9.60156V11.2785ZM3.09375 9.13398C2.56781 8.87383 2.09531 8.58203 1.6875 8.23398V9.21836C1.6875 9.48906 1.90582 10.1008 3.09375 10.7652V9.13398ZM11.5312 10.1711V11.8621C12.3645 11.725 13.1238 11.5246 13.7812 11.2785V9.60156C13.0887 9.81602 12.3328 10.041 11.5312 10.1711ZM16.3125 8.23398C15.9047 8.58203 15.402 8.87383 14.9062 9.13398V10.7652C16.0945 10.1008 16.3125 9.48906 16.3125 9.25V8.23398Z" fill="#B8B8B8"/>'
  }
})


