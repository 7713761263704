/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'move-down': {
    width: 20,
    height: 32,
    viewBox: '0 0 20 32',
    data: '<path pid="0" d="M20 10h-6V0H6v10H0l10 12 10-12zM0 32h20v-6H0v6z"/>'
  }
})
