/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cubes': {
    width: 29,
    height: 24,
    viewBox: '0 0 29 24',
    data: '<path pid="0" d="M8.571 21.857l5.143-2.571v-4.205l-5.143 2.196v4.58zm-.857-6.08l5.411-2.317-5.411-2.317-5.411 2.317zm14.572 6.08l5.143-2.571v-4.205l-5.143 2.196v4.58zm-.857-6.08l5.411-2.317-5.411-2.317-5.411 2.317zm-6-3.924l5.143-2.21V6.08l-5.143 2.196v3.576zm-.858-5.076l5.906-2.531-5.906-2.531-5.906 2.531zm14.572 6.937v5.571a1.69 1.69 0 01-.951 1.527l-6 3c-.241.134-.496.188-.763.188s-.522-.054-.763-.188l-6-3a.214.214 0 01-.094-.054.224.224 0 01-.094.054l-6 3c-.241.134-.496.188-.763.188s-.522-.054-.763-.188l-6-3a1.69 1.69 0 01-.951-1.527v-5.571a1.74 1.74 0 011.045-1.58l5.813-2.491V4.286a1.74 1.74 0 011.045-1.58l6-2.571c.214-.094.442-.134.67-.134s.455.04.67.134l6 2.571a1.742 1.742 0 011.045 1.58v5.357l5.813 2.491a1.72 1.72 0 011.045 1.58z"/>'
  }
})
