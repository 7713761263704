/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar': {
    width: 640,
    height: 640,
    viewBox: '0 0 640 640',
    data: '<path pid="0" d="M544 96h-32v64h-96V96H224v64h-96V96H96c-35 0-64 29-64 64v384c0 35 29 64 64 64h448c35 0 64-29 64-64V160c0-35-29-64-64-64zm0 448H96V288h448v256zM208 32h-64v112h64V32zm288 0h-64v112h64V32z"/>'
  }
})
