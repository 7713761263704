/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gear': {
    width: 28,
    height: 32,
    viewBox: '0 0 28 32',
    data: '<path pid="0" d="M13.998 10.938a5.063 5.063 0 000 10.127c2.794 0 5.045-2.271 5.045-5.064 0-2.796-2.25-5.063-5.045-5.063zm10.147 7.97l-.912 2.197 1.842 3.617-2.256 2.256-3.701-1.748-2.197.902-1.115 3.414-.143.453h-3.188l-1.38-3.854-2.197-.906-3.622 1.834-2.255-2.254 1.746-3.703-.905-2.195-3.86-1.26v-3.187l3.856-1.382.905-2.193-1.622-3.202-.214-.42 2.252-2.252L8.885 6.77l2.194-.907 1.114-3.412.144-.452h3.188l1.381 3.858 2.191.907 3.627-1.838 2.254 2.252-1.746 3.7.902 2.198 3.867 1.258v3.186l-3.855 1.387z"/>'
  }
})
