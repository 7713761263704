/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'apps': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: `
        <g clip-path="url(#clip0_700_4577)">
          <path d="M10 11.25H3.75C3.41848 11.25 3.10054 11.1183 2.86612 10.8839C2.6317 10.6495 2.5 10.3315 2.5 10V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H10C10.3315 2.5 10.6495 2.6317 10.8839 2.86612C11.1183 3.10054 11.25 3.41848 11.25 3.75V10C11.25 10.3315 11.1183 10.6495 10.8839 10.8839C10.6495 11.1183 10.3315 11.25 10 11.25ZM3.75 3.75V10H10V3.75H3.75Z"/>
          <path d="M16.25 7.5V10H13.75V7.5H16.25ZM16.25 6.25H13.75C13.4185 6.25 13.1005 6.3817 12.8661 6.61612C12.6317 6.85054 12.5 7.16848 12.5 7.5V10C12.5 10.3315 12.6317 10.6495 12.8661 10.8839C13.1005 11.1183 13.4185 11.25 13.75 11.25H16.25C16.5815 11.25 16.8995 11.1183 17.1339 10.8839C17.3683 10.6495 17.5 10.3315 17.5 10V7.5C17.5 7.16848 17.3683 6.85054 17.1339 6.61612C16.8995 6.3817 16.5815 6.25 16.25 6.25Z"/>
          <path d="M16.25 13.75V16.25H13.75V13.75H16.25ZM16.25 12.5H13.75C13.4185 12.5 13.1005 12.6317 12.8661 12.8661C12.6317 13.1005 12.5 13.4185 12.5 13.75V16.25C12.5 16.5815 12.6317 16.8995 12.8661 17.1339C13.1005 17.3683 13.4185 17.5 13.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V13.75C17.5 13.4185 17.3683 13.1005 17.1339 12.8661C16.8995 12.6317 16.5815 12.5 16.25 12.5Z"/>
          <path d="M10 13.75V16.25H7.5V13.75H10ZM10 12.5H7.5C7.16848 12.5 6.85054 12.6317 6.61612 12.8661C6.3817 13.1005 6.25 13.4185 6.25 13.75V16.25C6.25 16.5815 6.3817 16.8995 6.61612 17.1339C6.85054 17.3683 7.16848 17.5 7.5 17.5H10C10.3315 17.5 10.6495 17.3683 10.8839 17.1339C11.1183 16.8995 11.25 16.5815 11.25 16.25V13.75C11.25 13.4185 11.1183 13.1005 10.8839 12.8661C10.6495 12.6317 10.3315 12.5 10 12.5Z"/>
        </g>
    `
  }
})
