/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pulse': {
    width: 21,
    height: 24,
    viewBox: '0 0 21 24',
    data: '<path pid="0" d="M17.25 11.999L13.2 8.1l-3.3 4.65L8.25 2.4l-4.677 9.598H0v3.001h5.4l1.35-2.7 1.35 8.099 5.4-7.649 2.399 2.25H21v-3.001h-3.75z"/>'
  }
})
