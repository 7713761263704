/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plug': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M31.356 6.396l-.028-.028a1.98 1.98 0 00-2.8 0L27.1 7.796l-2.828-2.828L25.7 3.54a1.98 1.98 0 000-2.8l-.028-.028a1.98 1.98 0 00-2.8 0l-1.499 1.499a5.002 5.002 0 00-6.293.636l-.778.778c-3.407 3.407-3.811 8.678-1.214 12.528l-.129.129a5 5 0 00-.009 7.062c.388.39.395 1.041.004 1.428s-1.02.384-1.409-.005L8.75 21.972c-1.957-1.957-5.145-1.992-7.103-.036a5 5 0 00-.002 7.073l.274.274a1.98 1.98 0 002.8 0l.028-.028a1.98 1.98 0 000-2.8l-.259-.259a1.014 1.014 0 01-.034-1.409 1 1 0 011.434-.02l2.792 2.791c1.966 1.966 5.172 1.994 7.128.017a5 5 0 00-.013-7.045 1.003 1.003 0 01-.006-1.42l.13-.13c3.85 2.597 9.121 2.192 12.528-1.214l.778-.778a5.002 5.002 0 00.636-6.293l1.499-1.499a1.98 1.98 0 000-2.8zm-3.55 9.178a3 3 0 01-4.243 0l-7.071-7.071a3 3 0 013.415-4.829L18.6 4.981a1.98 1.98 0 000 2.8l.028.028a1.98 1.98 0 002.8 0l1.428-1.428 2.828 2.828-1.428 1.428a1.98 1.98 0 000 2.8l.028.028a1.98 1.98 0 002.8 0l1.307-1.307a3.002 3.002 0 01-.586 3.415z"/>'
  }
})
