/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hash': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M24.24.05l4.73.73-4.98 31.24-4.73-.72z"/><path pid="1" d="M.97 21.31L1 26.06l29.89-.2-.03-4.74z"/><path pid="2" d="M9.38.05l4.72.73-4.98 31.25-4.72-.73z"/><path pid="3" d="M1.81 7.18l.03 4.75 29.89-.2-.03-4.75z"/>'
  }
})
