/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'resize': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M23.977 28.965v-1.932h3.988v-3.988h2.057v5.92h-6.045zm3.988-22.998h-3.988V4.035h6.045v5.92h-2.057V5.967zM3.035 9.955H.979v-5.92h6.045v1.932H3.035v3.988zm1.932-1.932h21.066v16.953H4.967V8.023zm2.056 15.022h16.953V9.955H7.023v13.09zm1.995-11.033h13.027v8.977H9.018v-8.977zM3.035 27.033h3.988v1.932H.979v-5.92h2.057v3.988z"/>'
  }
})
