/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'back': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: `
      <svg xmlns="http://www.w3.org/2000/svg">
        <path d="M4.71829 11.8178L0.218728 7.93434C0.0624934 7.80659 -4.90485e-07 7.6533 -4.71954e-07 7.5C-4.53423e-07 7.3467 0.0624935 7.19341 0.218728 7.06566L4.71829 3.18217C4.99951 2.95222 5.46822 2.92667 5.74944 3.18217C6.06191 3.41211 6.09316 3.79535 5.78069 4.05084L1.78108 7.5L5.78069 10.9492C6.09316 11.2047 6.06191 11.5879 5.74944 11.8178C5.46822 12.0733 4.99951 12.0478 4.71829 11.8178Z"/>
        <path d="M8.71829 11.8178L4.21873 7.93434C4.06249 7.80659 4 7.6533 4 7.5C4 7.3467 4.06249 7.19341 4.21873 7.06566L8.71829 3.18217C8.99951 2.95222 9.46822 2.92667 9.74944 3.18217C10.0619 3.41211 10.0932 3.79535 9.78069 4.05084L5.78108 7.5L9.78069 10.9492C10.0932 11.2047 10.0619 11.5879 9.74944 11.8178C9.46822 12.0733 8.99951 12.0478 8.71829 11.8178Z"/>
      </svg>
    `
  }
})
