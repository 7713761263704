/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'solidity': {
    width: 768,
    height: 768,
    viewBox: '0 0 768 768',
    data: '<path pid="0" opacity=".45" d="M506.789 0L383.314 219.452H136.538L259.927 0h246.861z"/><path pid="1" opacity=".6" d="M383.315 219.452h246.864L506.79 0H259.929z"/><path pid="2" opacity=".8" d="M259.928 438.82l123.388-219.368L259.928 0 136.539 219.452z"/><path pid="3" opacity=".45" d="M261.123 767.999l123.475-219.454h246.864L507.986 767.999H261.123z"/><path pid="4" opacity=".6" d="M384.596 548.546H137.733L261.122 768h246.863z"/><path pid="5" opacity=".8" d="M507.986 329.179L384.597 548.547l123.389 219.454 123.475-219.454z"/>'
  }
})
