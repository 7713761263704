/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pointer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.452 13.086l3.485 8.101-2.625 1.312-3.049-8.32-4.826 4.382V1.499l13.125 10.5-6.11 1.086z"/>'
  }
})
