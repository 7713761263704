/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'triangle-arrow-right': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M6.8716 1.94482L10.8091 6.098C10.9458 6.23462 11.0005 6.39856 11.0005 6.5625C11.0005 6.72644 10.9458 6.89038 10.8091 7.027L6.8716 11.1802C6.62551 11.4261 6.21535 11.4534 5.96926 11.1802C5.69582 10.9343 5.66848 10.5244 5.94192 10.2512L9.44191 6.5625L5.94192 2.87382C5.66848 2.60058 5.69582 2.19073 5.96926 1.94482C6.21535 1.67158 6.62551 1.6989 6.8716 1.94482Z" fill="#B8B8B8"/>'
  }
})
