/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'json': {
    width: 200,
    height: 200,
    viewBox: '0 0 52.917 52.917',
    data: '<path pid="0" d="M-.204 14.925v23.066H53.12V14.925zm18.294 5.03c.87 0 1.897.103 3.082.306l.593.102-.152 1.524c-1.58-.181-2.698-.272-3.353-.272-1.434 0-2.15.503-2.15 1.508 0 .519.168.891.507 1.117.339.214 1.084.485 2.236.813 1.162.316 1.98.7 2.455 1.151.474.44.71 1.14.71 2.1 0 1.22-.349 2.134-1.049 2.743-.688.599-1.631.898-2.828.898-.96 0-2.02-.113-3.183-.34l-.61-.118.187-1.506c1.524.203 2.686.304 3.488.304 1.4 0 2.1-.62 2.1-1.862 0-.486-.158-.841-.475-1.067-.316-.237-1.027-.491-2.133-.762-1.107-.282-1.93-.666-2.473-1.152-.541-.485-.812-1.253-.812-2.303s.338-1.84 1.016-2.37c.688-.542 1.636-.813 2.844-.813zm10.177.018c1.738 0 2.957.496 3.657 1.49.7.982 1.05 2.5 1.05 4.555 0 2.055-.35 3.556-1.05 4.504-.688.948-1.907 1.423-3.657 1.423s-2.975-.475-3.675-1.423c-.7-.96-1.05-2.46-1.05-4.504 0-2.043.35-3.562 1.05-4.555.711-.994 1.937-1.49 3.675-1.49zm-17.896.186h1.862l.017 9.805c0 1.207-.253 2.009-.761 2.404s-1.4.593-2.676.593V31.3c.598 0 1.005-.09 1.22-.27.225-.181.338-.582.338-1.203zm24.792 0h3.285l3.522 9.94h.254v-9.94h1.88v11.6h-3.218l-3.607-9.957h-.237v9.956h-1.88zm-6.896 1.456c-1.061 0-1.79.35-2.185 1.05-.395.7-.592 1.818-.592 3.353 0 1.524.192 2.62.576 3.285.383.666 1.117.999 2.201.999s1.812-.327 2.184-.982c.384-.655.576-1.75.576-3.285 0-1.546-.198-2.67-.593-3.37-.384-.7-1.106-1.05-2.167-1.05z"/>'
  }
})
