/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'enlarge': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 0v13l-5-5-6 6-3-3 6-6-5-5zM14 21l-6 6 5 5H0V19l5 5 6-6z"/>'
  }
})
