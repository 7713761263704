/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clowd-down': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M13.1875 13.5H1.8125C1.3724 13.5 1 13.8438 1 14.25C1 14.6562 1.3724 15 1.8125 15H13.1875C13.6276 15 14 14.6562 14 14.25C14 13.8438 13.6276 13.5 13.1875 13.5ZM11.2578 6.5L8.3125 9.375V1.75C8.3125 1.34375 7.9401 1 7.5 1C7.0599 1 6.6875 1.34375 6.6875 1.75V9.375L3.74219 6.5C3.60677 6.34375 3.36979 6.25 3.16667 6.25C2.96354 6.25 2.76042 6.3125 2.625 6.46875C2.28646 6.75 2.2526 7.21875 2.59115 7.53125L6.92448 11.7812C7.22917 12.0625 7.77083 12.0625 8.07552 11.7812L12.4089 7.53125C12.7474 7.21875 12.7135 6.75 12.375 6.46875C12.0703 6.1875 11.5625 6.1875 11.2578 6.5Z" fill="#B8B8B8"/>'
  }
})
