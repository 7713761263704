/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'triangle-arrow-up': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M-.01 26.2L15.9 5.8l16.11 20.4z" fill-rule="evenodd"/>'
  }
})
