/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'addresses': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: `
    <path d="M9.5625 10.125H7.3125C5.75859 10.125 4.5 11.3836 4.5 12.9375C4.5 13.2469 4.75312 13.5 5.0625 13.5H11.8125C12.1231 13.5 12.375 13.2481 12.375 12.9375C12.375 11.3836 11.1164 10.125 9.5625 10.125ZM8.4375 9C9.68027 9 10.6875 7.99277 10.6875 6.75C10.6875 5.50723 9.68027 4.5 8.4375 4.5C7.19508 4.5 6.1875 5.50723 6.1875 6.75C6.1875 7.99277 7.19648 9 8.4375 9ZM17.4375 11.25H16.875V14.625H17.4375C17.7481 14.625 18 14.3731 18 14.0625V11.8125C18 11.5031 17.7469 11.25 17.4375 11.25ZM17.4375 2.25H16.875V5.625H17.4375C17.7469 5.625 18 5.37187 18 5.0625V2.8125C18 2.50172 17.7469 2.25 17.4375 2.25ZM17.4375 6.75H16.875V10.125H17.4375C17.7469 10.125 18 9.87187 18 9.5625V7.3125C18 7.00313 17.7469 6.75 17.4375 6.75ZM13.5 0H3.375C2.13223 0 1.125 1.00723 1.125 2.25V15.75C1.125 16.9928 2.13223 18 3.375 18H13.5C14.7428 18 15.75 16.9928 15.75 15.75V2.25C15.75 1.00723 14.741 0 13.5 0ZM14.0625 15.75C14.0625 16.0606 13.8106 16.3125 13.5 16.3125H3.375C3.06436 16.3125 2.8125 16.0606 2.8125 15.75V2.25C2.8125 1.93929 3.06436 1.6875 3.375 1.6875H13.5C13.8106 1.6875 14.0625 1.93929 14.0625 2.25V15.75Z" fill="#B8B8B8"/>
    `
  }
})
