/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'buffer': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M31.712 24.32c.383.189.383.356 0 .499l-15.143 6.964c-.383.193-.76.193-1.139 0L.283 24.819c-.379-.143-.379-.309 0-.5l3.629-1.64a1.22 1.22 0 011.136 0l10.383 4.764c.38.187.76.187 1.139 0l10.383-4.765a1.22 1.22 0 011.136 0l3.623 1.64zm0-8.605c.383.191.383.38 0 .568l-15.143 6.971c-.383.139-.76.139-1.139 0L.283 16.281c-.379-.191-.379-.379 0-.568l3.629-1.636c.379-.192.76-.192 1.136 0l10.383 4.76c.38.192.76.192 1.139 0l10.383-4.76a1.192 1.192 0 011.136 0l3.623 1.635zM.285 7.68c-.38-.191-.38-.356 0-.5L15.432.213a1.22 1.22 0 011.136 0l15.144 6.973c.383.143.383.309 0 .5L16.569 14.65c-.383.191-.76.191-1.139 0L.283 7.679z"/>'
  }
})
