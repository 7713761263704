import payloads from './payloads'

export default function () {
  return {
    expanded: false,
    payloads,
    value: undefined,
    types: {},
    requested: {},
    searchLink: null,
    type: null
  }
}
