/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'table': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M0 6v22h32V6H0zm12 14v-4h8v4h-8zm8 2v4h-8v-4h8zm0-12v4h-8v-4h8zm-10 0v4H2v-4h8zm-8 6h8v4H2v-4zm20 0h8v4h-8v-4zm0-2v-4h8v4h-8zM2 22h8v4H2v-4zm20 4v-4h8v4h-8z"/>'
  }
})
