/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zap': {
    width: 20,
    height: 32,
    viewBox: '0 0 20 32',
    data: '<path pid="0" d="M20 14h-8l6-14L0 18h8L2 32l18-18z"/>'
  }
})
