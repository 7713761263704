/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-right': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M12.0586 7.74744C12.3156 7.49041 12.3156 7.07478 12.0586 6.82048L9.43359 4.19275C9.17656 3.93572 8.76094 3.93572 8.50664 4.19275C8.25234 4.44978 8.24961 4.86541 8.50664 5.1197L10.0105 6.62361L0.65625 6.62634C0.292578 6.62634 0 6.91892 0 7.28259C0 7.64627 0.292578 7.93884 0.65625 7.93884H10.0105L8.50664 9.44275C8.24961 9.69978 8.24961 10.1154 8.50664 10.3697C8.76367 10.624 9.1793 10.6267 9.43359 10.3697L12.0586 7.7447V7.74744Z" fill="#B8B8B8"/>'
  }
})
