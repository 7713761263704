/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'server': {
    width: 12,
    height: 12,
    viewBox: '0 0 14 14',
    data: '<path d="M0.25 2.1875C0.25 1.46262 0.837617 0.875 1.5625 0.875H4.1875C4.91211 0.875 5.5 1.46262 5.5 2.1875V4.8125C5.5 5.53711 4.91211 6.125 4.1875 6.125H1.5625C0.837617 6.125 0.25 5.53711 0.25 4.8125V2.1875ZM1.5625 4.8125H4.1875V2.1875H1.5625V4.8125ZM0.25 9.1875C0.25 8.46289 0.837617 7.875 1.5625 7.875H4.1875C4.91211 7.875 5.5 8.46289 5.5 9.1875V11.8125C5.5 12.5371 4.91211 13.125 4.1875 13.125H1.5625C0.837617 13.125 0.25 12.5371 0.25 11.8125V9.1875ZM1.5625 11.8125H4.1875V9.1875H1.5625V11.8125ZM11.1875 0.875C11.9121 0.875 12.5 1.46262 12.5 2.1875V4.8125C12.5 5.53711 11.9121 6.125 11.1875 6.125H8.5625C7.83789 6.125 7.25 5.53711 7.25 4.8125V2.1875C7.25 1.46262 7.83789 0.875 8.5625 0.875H11.1875ZM11.1875 2.1875H8.5625V4.8125H11.1875V2.1875ZM7.25 9.1875C7.25 8.46289 7.83789 7.875 8.5625 7.875H11.1875C11.9121 7.875 12.5 8.46289 12.5 9.1875V11.8125C12.5 12.5371 11.9121 13.125 11.1875 13.125H8.5625C7.83789 13.125 7.25 12.5371 7.25 11.8125V9.1875ZM8.5625 11.8125H11.1875V9.1875H8.5625V11.8125Z" fill="#B8B8B8"/>'
  }
})
