/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-left': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M1.19332 7.24033C0.935754 7.49683 0.934896 7.91245 1.1914 8.16728L3.81097 10.8004C4.06747 11.058 4.48309 11.0589 4.73792 10.8023C4.99274 10.5458 4.99634 10.1302 4.73983 9.8754L3.23904 8.36839L12.5933 8.38498C12.957 8.38573 13.2502 8.09376 13.2509 7.73009C13.2517 7.36641 12.9597 7.07323 12.596 7.07248L3.24175 7.05315L4.74876 5.55236C5.00633 5.29586 5.00718 4.88023 4.75068 4.62541C4.49417 4.37058 4.07856 4.36699 3.82373 4.62349L1.19331 7.24306L1.19332 7.24033Z" fill="#B8B8B8"/>'
  }
})
