/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'miner': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M12.5863 3.07889C13.8879 4.89452 14.3199 7.24881 13.7348 9.40897C13.7266 9.43358 13.7211 9.46092 13.7129 9.48553C13.6773 9.60584 13.6418 9.72889 13.6008 9.84647L13.5953 9.86288C13.5543 9.98592 13.5078 10.1062 13.4586 10.2265C13.3109 10.5902 12.7777 10.582 12.6438 10.2129C12.5945 10.0871 12.5453 9.95858 12.4906 9.8328C12.4469 9.73163 12.4031 9.63045 12.3566 9.52928L12.3348 9.4828C11.5582 7.78202 10.3961 6.20428 9.09453 4.89999C7.80117 3.60663 6.22071 2.43084 4.51992 1.66249L4.47344 1.64061C4.37227 1.59413 4.26836 1.55038 4.16446 1.50663C4.03867 1.45467 3.91289 1.40545 3.78711 1.35624C3.42071 1.21952 3.4125 0.686313 3.77344 0.541391C3.89375 0.492173 4.01407 0.448423 4.13711 0.404673L4.14258 0.401938C4.26563 0.360923 4.38867 0.322642 4.51172 0.287095C4.53907 0.278892 4.56641 0.273423 4.59102 0.26522C6.74844 -0.317202 9.10274 0.114829 10.9156 1.41639L11.1754 1.14569C11.3395 0.976157 11.5637 0.880454 11.7988 0.87772C12.034 0.874985 12.2609 0.967954 12.425 1.13475L12.8625 1.57225C13.0293 1.73905 13.1223 1.96327 13.1195 2.19842C13.1168 2.43358 13.0211 2.6578 12.8516 2.82186L12.5809 3.08163L12.5863 3.07889ZM10.8855 3.09803C9.76172 1.97694 8.23047 1.3617 6.67735 1.32342C7.91875 2.08084 9.05352 3.00233 10.0242 3.97577C11.0059 4.95741 11.9219 6.09491 12.6766 7.33358C12.641 5.76952 12.0176 4.22733 10.8855 3.10077V3.09803ZM0.243362 12.518L7.68086 4.78241C7.9461 5.01209 8.21407 5.25819 8.47657 5.52069C8.73907 5.78319 8.98516 6.05116 9.21485 6.31639L1.48203 13.7566C1.1375 14.0875 0.593362 14.082 0.257034 13.7457C-0.079294 13.4094 -0.0847628 12.8625 0.243362 12.518Z" fill="#B8B8B8"/>'
  }
})
