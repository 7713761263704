/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'document-add': {
    width: 640,
    height: 640,
    viewBox: '0 0 640 640',
    data: '<path pid="0" d="M288 320v-64h64v64h64v64h-64v64h-64v-64h-64v-64h64zM128 576h384V192H384V64H128v512zm-64 32V0h384l128 128v512H64v-32z"/>'
  }
})
