/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pin': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M21.106 2.02c-.991-.914-2.244-1.157-2.81-.544-.425.461-.336 1.27.154 2.03L9.183 13.552l-.832-.767c-.991-.914-2.28-1.119-2.887-.46-.608.66-.3 1.926.69 2.841l4.267 3.935-5.605 6.076-2.886 5.685 5.33-3.43 5.605-6.077 4.266 3.935c.99.914 2.279 1.12 2.887.46.608-.659.3-1.926-.691-2.84l-.832-.767 9.267-10.046c.798.427 1.61.45 2.036-.01.566-.613.223-1.843-.768-2.757z"/>'
  }
})
