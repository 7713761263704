/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dots': {
    width: 200,
    height: 200,
    viewBox: '0 0 52.917 52.917',
    data: '<g transform="translate(1.436 2.088)" _fill="#2b0000"><circle pid="0" cx="25.023" cy="7.055" r="5.492"/><circle pid="1" cx="25.023" cy="24.37" r="5.492"/><circle pid="2" cx="25.023" cy="41.686" r="5.492"/></g>'
  }
})
