/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'grid': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M192 288H32c-17.688 0-32 14.312-32 32v160c0 17.688 14.312 32 32 32h160c17.688 0 32-14.312 32-32V320c0-17.688-14.312-32-32-32zm-32 160H64v-96h96v96zM192 0H32C14.312 0 0 14.312 0 32v160c0 17.688 14.312 32 32 32h160c17.688 0 32-14.312 32-32V32c0-17.688-14.312-32-32-32zm-32 160H64V64h96v96zm320 128H320c-17.688 0-32 14.312-32 32v160c0 17.688 14.312 32 32 32h160c17.688 0 32-14.312 32-32V320c0-17.688-14.312-32-32-32zm-32 160h-96v-96h96v96zM480 0H320c-17.688 0-32 14.312-32 32v160c0 17.688 14.312 32 32 32h160c17.688 0 32-14.312 32-32V32c0-17.688-14.312-32-32-32zm-32 160h-96V64h96v96z"/>'
  }
})
