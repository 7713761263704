/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'btc': {
    width: 1024,
    height: 1024,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#1a1a1a" d="M969.423 626.148c-62.964 252.72-318.866 406.394-571.546 343.352C145.316 907.008-8.516 650.556 54.604 398.23 117.49 145.315 373.47-8.478 625.992 54.447c252.719 63.081 406.472 318.944 343.43 571.78v-.078zM719.848 444.844c9.432-62.492-38.281-96.292-103.76-119.088l21.223-84.62-51.684-12.97-20.634 82.812c-13.56-3.42-27.708-6.563-41.818-9.826l20.674-83.597-51.88-12.97-21.224 85.09c-11.201-2.633-22.206-5.188-33.014-7.86l-71.335-17.686-13.756 55.3s38.32 8.842 37.535 9.274c21.027 5.346 24.76 19.102 24.17 30.107l-58.05 232.674c-2.948 6.525-9.433 15.957-24.132 12.34.59.787-37.73-9.432-37.73-9.432l-25.94 59.348 67.208 16.743 36.551 9.511-21.223 86.074 51.88 12.852 21.223-85.288c14.15 3.93 27.709 7.468 41.269 10.73l-20.045 84.659 51.88 12.97 21.42-86.074c88.038 16.783 154.46 10.1 182.366-69.724 22.402-64.339-1.18-101.402-47.832-125.613 33.565-7.586 58.955-29.87 66.03-75.855h.393zM601.546 610.702c-15.878 64.457-124.08 29.478-159.177 20.83l28.298-113.978c35.215 9.04 147.662 26.333 130.879 93.148zm16.114-166.645c-14.542 58.561-104.625 28.888-133.827 21.617l25.704-103.76c29.242 7.075 123.294 20.595 108.083 81.907v.236z"/>'
  }
})
