/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cube': {
    width: 22,
    height: 24,
    viewBox: '0 0 22 24',
    data: '<path d="M12.0672 3.67237C12.6672 3.44254 13.3328 3.44254 13.9328 3.67237L23.3078 7.2645C24.325 7.65513 25 8.64093 25 9.74416V21.2878C25 22.3911 24.325 23.3759 23.3078 23.7689L13.9328 27.358C13.3328 27.5473 12.6672 27.5473 12.0672 27.358L2.69406 23.7689C1.67453 23.3759 1 22.3911 1 21.2878V9.74416C1 8.64093 1.67453 7.65513 2.69406 7.2645L12.0672 3.67237ZM13.1312 5.79716C13.0469 5.76449 12.9109 5.76449 12.8687 5.79716L3.98219 9.19965L13 12.7792L22.0187 9.19965L13.1312 5.79716ZM3.49187 21.6429L11.875 24.8532V14.7773L3.25 11.354V21.2878C3.25 21.4441 3.34656 21.5861 3.49187 21.6429ZM14.125 24.8532L22.5062 21.6429C22.6516 21.5861 22.75 21.4441 22.75 21.2878V11.354L14.125 14.7773V24.8532Z" fill="#B8B8B8"/>'
  }
})
