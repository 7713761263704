/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'triangle-arrow-down': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M-.01 5.8L15.9 26.2 32.01 5.8z" fill-rule="evenodd"/>'
  }
})
