/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'csv': {
    width: 200,
    height: 200,
    viewBox: '0 0 52.917 52.917',
    data: '<path pid="0" d="M-.204 14.925v23.066H53.12V14.925H-.204zm26.033 5.539c.87 0 1.897.102 3.082.305l.593.102-.152 1.523c-1.58-.18-2.698-.27-3.353-.27-1.434 0-2.15.502-2.15 1.507 0 .519.168.891.507 1.117.339.214 1.084.485 2.236.813 1.162.316 1.98.7 2.455 1.152.474.44.71 1.14.71 2.1 0 1.218-.349 2.133-1.049 2.742-.688.598-1.631.898-2.828.898-.96 0-2.02-.113-3.183-.34l-.61-.118.187-1.506c1.524.203 2.686.304 3.488.304 1.4 0 2.1-.62 2.1-1.862 0-.486-.158-.841-.475-1.067-.316-.237-1.027-.491-2.133-.762-1.107-.283-1.93-.666-2.472-1.152-.542-.485-.813-1.252-.813-2.302s.338-1.84 1.016-2.371c.688-.542 1.637-.813 2.844-.813zm-8.566.017c1.004 0 2.11.14 3.318.423l-.068 1.524a17.38 17.38 0 00-3.047-.27c-1.016 0-1.705.304-2.066.913-.362.599-.542 1.734-.542 3.404 0 1.66.175 2.794.525 3.404.35.598 1.033.897 2.049.897s2.043-.085 3.081-.254l.052 1.558a15.833 15.833 0 01-3.302.373c-1.694 0-2.845-.469-3.455-1.406-.598-.937-.897-2.478-.897-4.623s.304-3.668.914-4.572c.61-.914 1.755-1.371 3.438-1.371zm13.362.186h1.964l2.303 9.94h.847l2.303-9.94h1.964l-2.845 11.6H33.47l-2.845-11.6z"/>'
  }
})
