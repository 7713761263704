/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'light-bulb': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 2C10.477 2 6 6.477 6 12a9.982 9.982 0 004 8v6a2 2 0 002 2 2 2 0 002 2h4a2 2 0 002-2 2 2 0 002-2v-6a9.983 9.983 0 004-8c0-5.523-4.477-10-10-10zm4 23a1 1 0 01-1 1h-6a1 1 0 01-1-1v-1h8v1zm2-7.709c-1.039 1.032-2 1.325-2 3.876V22h-2v-4l4-4v-2l-2-2-2 2-2-2-2 2-2-2-2 2v2l4 4v4h-2v-.833c0-2.552-.961-2.844-2-3.876a8 8 0 1112 0zM16 18l-4-4v-2l2 2 2-2 2 2 2-2v2l-4 4z"/>'
  }
})
