/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stats': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22 8h-6v20h6V8zm8 6h-6v14h6V14zM2 30v-4h2v-2H2v-4h2v-2H2v-4h2v-2H2V8h2V6H2V2h2V0H0v32h32v-2H2zm12-12H8v10h6V18z"/>'
  }
})
