/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'transaction': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M2.81631 13.5898C3.07334 13.8469 3.48896 13.8469 3.74326 13.5898C3.99756 13.3328 4.00029 12.9172 3.74326 12.6629L2.23936 11.159L11.5937 11.1562C11.9573 11.1562 12.2499 10.8637 12.2499 10.5C12.2499 10.1363 11.9573 9.84374 11.5937 9.84374H2.23936L3.74326 8.33984C4.00029 8.08281 4.00029 7.66718 3.74326 7.41288C3.48623 7.15859 3.07061 7.15585 2.81631 7.41288L0.191309 10.0351C-0.0657227 10.2922 -0.0657227 10.7078 0.191309 10.9621L2.81631 13.5871V13.5898ZM12.0585 3.96484C12.3155 3.70781 12.3155 3.29218 12.0585 3.03788L9.4335 0.41015C9.17646 0.153119 8.76084 0.153119 8.50654 0.41015C8.25225 0.667181 8.24951 1.08281 8.50654 1.3371L10.0104 2.84101L0.656152 2.84374C0.29248 2.84374 -9.76641e-05 3.13632 -9.76641e-05 3.49999C-9.76641e-05 3.86367 0.29248 4.15624 0.656152 4.15624H10.0104L8.50654 5.66015C8.24951 5.91718 8.24951 6.33281 8.50654 6.5871C8.76357 6.8414 9.1792 6.84413 9.4335 6.5871L12.0585 3.9621V3.96484Z" fill="#B8B8B8"/>'
  },
  'transactions': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path d="M2.81631 13.5898C3.07334 13.8469 3.48896 13.8469 3.74326 13.5898C3.99756 13.3328 4.00029 12.9172 3.74326 12.6629L2.23936 11.159L11.5937 11.1562C11.9573 11.1562 12.2499 10.8637 12.2499 10.5C12.2499 10.1363 11.9573 9.84374 11.5937 9.84374H2.23936L3.74326 8.33984C4.00029 8.08281 4.00029 7.66718 3.74326 7.41288C3.48623 7.15859 3.07061 7.15585 2.81631 7.41288L0.191309 10.0351C-0.0657227 10.2922 -0.0657227 10.7078 0.191309 10.9621L2.81631 13.5871V13.5898ZM12.0585 3.96484C12.3155 3.70781 12.3155 3.29218 12.0585 3.03788L9.4335 0.41015C9.17646 0.153119 8.76084 0.153119 8.50654 0.41015C8.25225 0.667181 8.24951 1.08281 8.50654 1.3371L10.0104 2.84101L0.656152 2.84374C0.29248 2.84374 -9.76641e-05 3.13632 -9.76641e-05 3.49999C-9.76641e-05 3.86367 0.29248 4.15624 0.656152 4.15624H10.0104L8.50654 5.66015C8.24951 5.91718 8.24951 6.33281 8.50654 6.5871C8.76357 6.8414 9.1792 6.84413 9.4335 6.5871L12.0585 3.9621V3.96484Z" fill="#B8B8B8"/>'
  }
})
