/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 1024,
    height: 1024,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M907.5 755.1L562.4 196.7c-27.8-44.9-73-45-100.8 0L116.5 755.1C79.2 815.4 106.3 864 176.9 864h670.2c70.5 0 97.6-48.7 60.4-108.9zM512 768c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-159.9c0 17.4-14.3 31.9-32 31.9-17.8 0-32-14.3-32-31.9V415.9c0-17.4 14.3-31.9 32-31.9 17.8 0 32 14.3 32 31.9v192.2z"/>'
  }
})
