/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'database': {
    width: 24,
    height: 32,
    viewBox: '0 0 24 32',
    data: '<path pid="0" d="M12 30c-6.626 0-12-1.793-12-4v-4c0-.348.174-.678.424-1 1.338 1.723 5.99 3 11.576 3s10.238-1.277 11.576-3c.25.322.424.652.424 1v4c0 2.207-5.375 4-12 4zm0-8c-6.626 0-12-1.793-12-4v-4c0-.212.08-.418.188-.622.061-.128.141-.254.236-.378 1.338 1.722 5.99 3 11.576 3s10.238-1.278 11.576-3c.096.124.176.25.236.378.107.204.188.41.188.622v4c0 2.207-5.375 4-12 4zm0-8c-6.626 0-12-1.792-12-4V8 6c0-2.208 5.374-4 12-4s12 1.792 12 4v4c0 2.208-5.375 4-12 4zm0-10c-4.418 0-8 .894-8 2s3.582 2 8 2 8-.894 8-2-3.582-2-8-2z"/>'
  }
})
