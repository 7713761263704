/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'rocket': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22.398 3.877c-2.248 1.303-4.626 3.004-6.826 5.205a31.952 31.952 0 00-3.45 4.056l-5.574 1.03-6.547 6.551 5.838.182L9.95 16.79c-1.451 3.007-1.579 5.001-1.579 5.001l1.839 1.837s2.057-.195 5.086-1.661l-4.199 4.194.184 5.84 6.55-6.548 1.034-5.602a30.65 30.65 0 004.054-3.423c2.201-2.202 3.901-4.579 5.205-6.827-1.437-.292-2.754-.89-3.794-1.927-1.04-1.043-1.642-2.36-1.931-3.797zm2.17-1.163c.156 1.406.623 2.54 1.399 3.312.774.781 1.906 1.247 3.317 1.401C31.346 3.26 31.999 0 31.999 0s-3.26.652-7.431 2.714z"/>'
  }
})
